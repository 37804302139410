<template>
  <div id="logout" />
</template>

<script>
/**
 * LogOut
 *
 * This component calls the global store to clear the existing auth
 * session then redirects back to the log in page.
 */
export default {
  name: 'LogOut',
  created () {
    this.$store.dispatch('auth/logout')
  }
}
</script>
